
import { defineComponent } from 'vue'
import Main from '@/templates/Main.vue'
import AddGnomeSection from '@/components/sections/dashboard/AddGnomeSection.vue'

export default defineComponent({
  name: 'AddGnome',
  components: {
    Main,
    AddGnomeSection
  }
})
